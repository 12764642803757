<template>
  <div class="width-100">
    <div class="columns">
      <div class="column is-flex is-flex-direction-column is-justify-content-center">
        <p class="is-flex is-align-items-flex-start title is-5 mb-1">
          {{ repair.repair }}
        </p>
        <p v-if="!isModal">{{ notesLength }}</p>
      </div>
      <div class="column is-flex is-flex-direction-column is-justify-content-center">
        <div class="is-flex is-flex-direction-row is-align-items-center">
          <b-tooltip v-if="repair.totalSteps !== 0" :label="`Step ${repair.currentStep} of ${repair.totalSteps}`"  class="mr-3" :position="tooltipPosition">
            <radial-progress-bar
              inner-stroke-color="#f5f5f5"
              :start-color="convert(repair.progress[repair.progress.length-1]).hex"
              :stop-color="convert(repair.progress[repair.progress.length-1]).hex"
              :stroke-width="8"
              :inner-stroke-width="5"
              :diameter="70"
              :completed-steps="repair.currentStep"
              :total-steps="repair.totalSteps"
            >{{ repair.currentStep }}/{{ repair.totalSteps }}
            </radial-progress-bar>
          </b-tooltip>
          <div v-else class="noSteps">
            <p>N/A</p>
          </div>
          <b-tooltip :label="convert(repair.progress[repair.progress.length-1]).time"  :position="tooltipPosition">
            <p class="mb-0">
              <strong>{{ convert(repair.progress[repair.progress.length-1]).status }}</strong>
            </p>
            <p>{{ convert(repair.progress[repair.progress.length-1]).time }}</p>
          </b-tooltip>
        </div>
      </div>
      <div class="column is-flex is-flex-direction-column is-justify-content-center">
        <div class="is-hidden-touch">
          <p class="is-flex is-align-items-flex-start mb-2">
            <b-tooltip label="Customer" :position="tooltipPosition">
              <b-icon icon="cellphone-cog" class="mr-1" />
            </b-tooltip>
            <strong>{{ repair.customer }}</strong>
          </p>
          <p class="is-flex is-align-items-flex-start">
            <b-tooltip label="Tech" :position="tooltipPosition">
              <b-icon icon="tools" class="mr-1" />
            </b-tooltip>
            <span>{{ repair.tech }}</span>
          </p>
        </div>
        <div class="is-hidden-desktop is-flex is-flex-direction-row is-justify-content-space-between">
          <p class="is-flex is-align-items-flex-start mr-2">
            <b-icon icon="cellphone-cog" class="mr-1" />
            <strong>{{ repair.customer }}</strong>
          </p>
          <p class="is-flex is-align-items-flex-start">
            <b-icon icon="tools" class="mr-1" />
            <span>{{ repair.tech }}</span>
          </p>
        </div>
      </div>
    </div>

    <div v-if="isModal">
      <div class="columns">
        <div class="column">
          <p class="mb-2"><strong>Progress:</strong></p>
          <ul>
            <li v-for="(update, updateIdx) in repair.progress" :key="updateIdx">
              <strong>{{ convert(update).status }}</strong> | {{ convert(update).time }}
            </li>
          </ul>
        </div>
        <div class="column">
          <div v-if="repair.notes.length !== 0">
            <p class="mb-2"><strong>Notes:</strong></p>
            <ul>
              <li v-for="(note, noteIdx) in repair.notes" :key="noteIdx">
                {{ note }}
              </li>
            </ul>
          </div>
          <div v-else class="mb-2">
            <strong>No notes</strong>
          </div>
        </div>
      </div>
      <div class="columns">
        <div v-if="repair['before-repair']" class="column">
          <p class="mb-2">
            <strong>Before repair:</strong>
          </p>
          <p>{{ repair['before-repair'].notes }}</p>
          <p v-if="repair['before-repair'].notes == 0 && displayChecklist(repair['before-repair']).length == 0">No issues reported</p>
          <ul>
            <li v-for="(item, itemIdx) in displayChecklist(repair['before-repair'])" :key="itemIdx">
              <p v-if="item.value">{{ item.name }}</p>
            </li>
          </ul>
        </div>
        <div class="column">
          <div v-if="repair['after-repair']">
            <p class="mb-2">
              <strong>After repair:</strong>
            </p>
            <p>{{ repair['after-repair'].notes }}</p>
            <p v-if="repair['after-repair'].notes == 0 && displayChecklist(repair['after-repair']).length == 0">No issues reported</p>
            <ul>
              <li v-for="(item, itemIdx) in displayChecklist(repair['after-repair'])" :key="itemIdx">
                <p v-if="item.value">{{ item.name }}</p>
              </li>
            </ul>
          </div>
          <p v-else>
            <strong>No post repair checklist</strong>
          </p>
        </div>
      </div>
      <div class="width-100 is-flex is-justify-content-flex-end">
        <b-button class="is-danger" icon-right="delete" @click="confirmDelete()">Delete</b-button>
      </div>
    </div>

    <!-- the old dropdown used to show updates -->
    <!-- <p class="column customCol">
      <b-tooltip :label="convert(repair.progress[repair.progress.length-1]).time" >
        <b-dropdown aria-role="list">
          <button
            class="button"
            slot="trigger"
            :class="`${convert(repair.progress[repair.progress.length-1]).type}`"
          >
            <span>{{ convert(repair.progress[repair.progress.length-1]).status }}</span>
            <b-icon icon="menu-down"></b-icon>
          </button>
          <b-dropdown-item
            v-for="(update, updateIdx) in repair.progress"
            :key="updateIdx"
            aria-role="listitem"
            class="unclickable"
          >{{ convert(update).time }} | {{ convert(update).status }}</b-dropdown-item>
        </b-dropdown>
      </b-tooltip>
    </p> -->

  </div>
</template>

<style lang="scss" scoped>
.noSteps {
  width: 80px;
  text-align: center;
}
</style>

<script>
import RadialProgressBar from 'vue-radial-progress';
import { db } from '@/main';

export default {
  name: 'repairLogRow',
  props: ["repair", "isModal"],
  components: {
    RadialProgressBar
  },
  computed: {
    tooltipPosition () {
      if (this.isModal) return 'is-bottom'
      else return 'is-top'
    },
    notesLength () {
      let length = this.repair.notes.length;
      if (length == 0) {
        return `No notes`
      } else if (length == 1) {
        return `1 note`
      } else {
        return `${length} notes`
      }
    },
  },
  methods: {
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting repair',
        message: 'Are you sure you want to <b>delete</b> this repair? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          db.collection('shops').doc(this.$store.state.user.meta.shop_id).collection('repairLog').doc(this.repair.id).delete()
            .then(() => {
              this.$emit('close');
              this.$buefy.toast.open('Repair deleted');
            })
            .catch(() => {
              this.$buefy.toast.open('Delete failed. Try again');
            })
        }
      })
    },
    convert(object) {
      let ret = {};
      // time is not dependant on status, so we always calculate it
      let prettyDate = new Date(object.date.seconds * 1000).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
      let array = prettyDate.split(', ')
      ret.time = `${array[1]}, ${array[0]}` // returns it as HH:MM, DD/MM/YYYY
      switch (object.status) {
        case "finished-repair":
          ret.status = "Completed";
          ret.hex = "#48c774";
          ret.type = "is-success";
          ret.textColor = "has-text-success"
          break;
        case "in-progress":
          ret.status = "Started";
          ret.hex = "#ffdb4a";
          ret.type = "is-warning";
          ret.textColor = "has-text-warning"
          break;
        case "quit-repair":
          ret.status = "Quit Early";
          ret.hex = "#f14668";
          ret.type = "is-danger";
          ret.textColor = "has-text-danger"
          break;
      }
      return ret;
    },
    displayChecklist (list) {
      let array = [];
      list.values.forEach(element => {
        if (element.value) {
          array.push(element)
        }
      });
      return array
    }
  }
}
</script>