
<template>
  <div
    class="fullScreen has-text-left is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-start"
  >
    <p class="title is-5 mb-0">Dashboard</p>
    <b-tooltip  label="Click to copy" class="mt-3 mb-4">
      <p class="subtitle is-6 is-flex is-flex-direction-row is-align-items-center">
        <span class="has-text-weight-bold">Shop ID:</span>
        <span @click="copyID()" class="is-clickable is-flex is-flex-direction-row is-align-items-center">
          <span class="mx-2">{{ user.meta.shop_id }}</span>
          <b-icon type="is-primary" icon="content-copy"></b-icon>
        </span>
      </p>
    </b-tooltip>

    <div class="stepsContainer" v-if="repairLog.length > 0">
      <div class="customRow">
        <ul>
          <li class="columns box is-clickable" v-for="(repair, idx) in repairLog" :key="idx" @click="selectCard(repair)">
            <repairLogRow :repair="repair" :isModal="false" />
          </li>
        </ul>
      </div>

      <!-- modal popup -->
      <b-modal v-if="showRepairModal" v-model="showRepairModal" scroll="keep">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <repairLogRow :repair="selectedRepair" :isModal="true" @close="showRepairModal = false" />
            </div>
          </div>
        </div>
      </b-modal>

    </div>
    <div v-else>
      <p class="title is-5">No repairs started yet.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import repairLogRow from '@/components/repairLogRow'

export default {
  name: "Dashboard",
  title: "Dashboard",
  components: {
    repairLogRow
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      repairLog: "getRepairLog",
    })
  },
  data() {
    return {
      showRepairModal: false,
      selectedRepair: null,
    };
  },
  methods: {
    async copyID () {
      let msg, type;
      await this.$copyText(this.user.meta.shop_id).then(() => {
        msg = 'Copied ID';
        type = 'is-black'
      }, () => {
        msg = 'Could not copy ID';
        type = 'is-danger'
      })
      this.$buefy.toast.open({ message: msg, type: type });
    },
    selectCard(repair) {
      this.selectedRepair = repair;
      this.showRepairModal = true;
    },
  }
};
</script>

<style lang="scss" scoped>
.columns {
  margin-top: 0 !important;
}

.unclickable {
  cursor: default !important;
}

// .customParent {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

// .stepsContainer {
//   height: 75vh !important;
//   max-height: 75vh !important;
//   // overflow: scroll;
// }
// @media only screen and (max-width: 768px) {
//   .stepsContainer {
//     height: 78vh !important;
//   }
// }
</style>